import PropTypes from "prop-types";
import React from "react";

import "./hero-page.scss";

const HeroPage = ({ title, body, imageUrl }) => (
  <section className="hero-page">
    <div className="hero-page__split-container grid grid--constrained">
      <div className="hero-page__hero-content grid__item grid__item--offset-1-laptop grid__item--span-4-laptop">
        <div className="hero-page__text">
          <h1 className="heading-1">{title}</h1>
          <p className="body-text">{body}</p>
        </div>
      </div>
      <img
        className="hero-page__image grid__item grid__item--span-5-laptop grid__item--offset-7-laptop"
        src={imageUrl}
        alt=""
      />
    </div>
  </section>
);

HeroPage.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  imageUrl: PropTypes.string,
};

export default HeroPage;
