import React from "react";

import Calculator from "../../components/calculator/calculator";
import CtaButton from "../../components/cta-button/cta-button";
import HeroPage from "../../components/hero-page/hero-page";
import Layout from "../../components/layout/layout";
import Seo from "../../components/seo/seo";

import "./savings-calculator.scss";

const heroPageAttrs = {
  body:
    "Inbound inquiries from your fans across multiple channels adds up. Wasted time means less efficiency for your team.",
  imageUrl: "/images/savings-calculator/desktop-hero.png",
  title: "Do You Know How Much Time & Money Your Team is Wasting?",
};

const calculatorCta = {
  href: "/case-study/arsenal/",
  label: "Learn How We Helped Arsenal Unlock Critical Fan Insights",
};

const SavingCalculator = () => (
  <div className="savings-calculator-page">
    <Layout>
      <HeroPage
        className="savings-calculator-hero"
        body={heroPageAttrs.body}
        imageUrl={heroPageAttrs.imageUrl}
        title={heroPageAttrs.title}
      />

      <Calculator className="savings-calculator" />

      <div className="savings-calculator-cta grid grid--constrained">
        <CtaButton
          className="grid__item grid__item--span-6-laptop"
          href={calculatorCta.href}
          label={calculatorCta.label}
        />
      </div>
    </Layout>
  </div>
);

export const Head = ({ location }) => {
  const seoMeta = {
    metaTitle: "Saving Calculator",
    locale: "en-US",
    slug: location.pathname,
  };
  return <Seo {...seoMeta} />;
};

export default SavingCalculator;
