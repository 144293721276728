import React, { useState } from "react";

import Slider from "../slider/slider";

import "./calculator.scss";

const defaultSliderOptions = { max: 1000, min: 10, step: 5 };

const Calculator = () => {
  const [slidersValue, setSlidersValue] = useState({
    "slider-1": 0,
    "slider-2": 0,
    "slider-3": 0,
    "slider-4": 0,
  });

  function onSliderUpdate(id, value) {
    setSlidersValue(prevSliders => ({ ...prevSliders, [id]: value }));
  }

  const sliders = [
    {
      id: "slider-1",
      label: "Social Networks Direct Messages Per Week",
      options: {
        defaultValue: 50,
        onSliderUpdate,
        ...defaultSliderOptions,
      },
    },
    {
      id: "slider-2",
      label: "Inbound Phone Calls Per Week",
      options: {
        defaultValue: 250,
        onSliderUpdate,
        ...defaultSliderOptions,
      },
    },
    {
      id: "slider-3",
      label: "Text Messages Received By Staff",
      options: {
        defaultValue: 75,
        onSliderUpdate,
        ...defaultSliderOptions,
      },
    },
    {
      id: "slider-4",
      label: "Current Chats Received (If Applicable)",
      options: {
        defaultValue: 0,
        onSliderUpdate,
        ...defaultSliderOptions,
      },
    },
  ];

  const sliderElements = sliders.map(slider => (
    <div className="calculator__slider__container" key={`slider-${slider.id}`}>
      <label className="calculator__slider__label">{slider.label}</label>
      <Slider id={slider.id} {...slider.options} />
    </div>
  ));

  function subHeaderData() {
    const totalInquiries = Object.values(slidersValue).reduce(
      (accum, value) => accum + value,
      0
    );
    const weeklyEstimatedStaffTime = totalInquiries * 5;
    const weeklyEstimatedCost = weeklyEstimatedStaffTime * 0.86;
    const yearlyEstimatedCost = weeklyEstimatedCost * 52;

    return {
      totalInquiries,
      weeklyEstimatedStaffTime,
      weeklyEstimatedCost,
      yearlyEstimatedCost,
    };
  }
  const {
    totalInquiries,
    weeklyEstimatedStaffTime,
    weeklyEstimatedCost,
    yearlyEstimatedCost,
  } = subHeaderData();

  function formatToUSD(value) {
    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  }

  const sections = [
    [{ label: "Total Inquiries This Week", value: totalInquiries }],
    [
      {
        label: "Estimated Staff Time Used This Week",
        format: v => {
          const hours = Math.floor(v / 60);
          const minutes = v % 60;
          return `${hours}hr ${minutes}min`;
        },
        value: weeklyEstimatedStaffTime,
      },
      {
        label: "Estimated Cost This Week",
        format: v => formatToUSD(v),
        value: weeklyEstimatedCost,
      },
    ],
  ];
  const sectionElements = sections.map((section, sectionIndex) => (
    <React.Fragment key={`section-${sectionIndex}`}>
      <hr className="calculator__section__break" />
      {section.map((subSection, subSectionIndex) => (
        <span
          className="calculator__subsection__container"
          key={`subsection-${subSectionIndex}`}
        >
          <p className="calculator__subsection__label">{subSection.label}</p>
          <h4 className="calculator__subsection__value">
            {subSection.format
              ? subSection.format(subSection.value)
              : subSection.value}
          </h4>
        </span>
      ))}
    </React.Fragment>
  ));

  return (
    <div className="calculator grid grid--constrained">
      <h3 className="calculator__header grid__item grid__item--span-6-laptop">
        Enter your estimated weekly totals below:
      </h3>
      <div className="calculator__sliders grid__item grid__item--span-6-laptop">
        {sliderElements}
      </div>
      <section className="calculator__sections grid__item grid__item--span-6-laptop">
        {sectionElements}
      </section>
      <div className="calculator__cta grid__item grid__item--offset-7-laptop grid__item--span-4-laptop">
        <span className="calculator__cta__header">
          Estimated Cost This Year
        </span>
        <div className="calculator__cta__value">
          {formatToUSD(yearlyEstimatedCost)}
        </div>
      </div>
      <span className="calculator__footer grid__item">
        (Calculation: Staff time 5 min call average & $0.86/minute based on 2
        staff members at $55,000/year)
      </span>
    </div>
  );
};

export default Calculator;
