import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import "./slider.scss";

const Slider = ({ defaultValue, id, max, min, onSliderUpdate, step }) => {
  const [progressValue, setProgressValue] = useState(defaultValue);

  // on initial render, we need to update the Calculator's slidersValue
  useEffect(() => {
    onSliderUpdate(id, defaultValue);
  }, [id, defaultValue]);

  function computeLinearGradient(num) {
    const value = ((Number(num) - min) / (max - min)) * 100;
    return `linear-gradient(to right, #90DDD2 0%, #90DDD2 ${value}%, #E6E6E6 ${value}%, #E6E6E6 100%)`;
  }

  /* enforces number inputs only
   *  [backspace, arrow keys, alt, option and Cmd(Ctrl)] will not get invoked by this function.
   *  but "space" will, which will fail the test and will cancel the event.
   */
  function enforceNumbersOnly(e) {
    return !/[0-9\/]+/.test(e.data) && e.preventDefault();
  }

  function handleOnInput(e) {
    const valueAsNumber = Number(e.target.value);

    /* fro-152:
     * this check will ensure not to update the progressValue pass it's max.
     * for negative values, users will not be able to input '-' due to enforceNumbersOnly func.
     * having to check less than 0, will allow users to empty the value state and won't allow users to reach negative numbers using arrow down
     */
    if (valueAsNumber > max || valueAsNumber < 0) {
      return;
    }

    if (onSliderUpdate) {
      onSliderUpdate(id, valueAsNumber);
    }

    setProgressValue(e.target.value);
  }

  return (
    <div className="slider">
      <input
        className="slider__range"
        max={max}
        min={min}
        onInput={handleOnInput}
        step={step}
        style={{ background: computeLinearGradient(progressValue) }}
        type="range"
        value={Number(progressValue)}
      />
      <input
        className="slider__input"
        onBeforeInput={enforceNumbersOnly}
        onInput={handleOnInput}
        type="number"
        value={progressValue}
      />
    </div>
  );
};

Slider.propTypes = {
  defaultValue: PropTypes.number,
  id: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  onSliderUpdate: PropTypes.func,
  step: PropTypes.number,
};

Slider.defaultProps = {
  defaultValue: 50,
  id: "",
  max: 100,
  min: 0,
  step: 1,
};

export default Slider;
